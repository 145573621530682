import React, { useState, useEffect, useContext, createContext } from "react";
import _ from "lodash";
import useLocalState from "./use-local-state";
import useLocalStorage from "./use-localstorage";


const AuthContext = createContext();

const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const useAuthProvider = () => {
  const { setUser } = useLocalState();

  const [expired, setExpired, removeExpired] = useLocalStorage(
    `${process.env.REACT_APP_TOKEN}_expired`,
    null
  );

  const [token, setToken, removeToken] = useLocalStorage(
    process.env.REACT_APP_TOKEN,
    null
  );

  const signOut = () => {
    removeToken();
    removeExpired();
    setUser(null);
  };

  const setAuthToken = (token) => {
    setToken(token);
  };

  return { token, signOut, setAuthToken, expired, setExpired, removeExpired };
};

export default useAuth;
export { AuthProvider };

  //cookies
  // const [cookies, setCookie, removeCookie] = useCookies([process.env.REACT_APP_TOKEN]);
  // const token = cookies[process.env.REACT_APP_TOKEN];
  // const setToken = (value) => {
  //   setCookie(process.env.REACT_APP_TOKEN, value)
  // }
  // const removeToken = () => {
  //   removeCookie(process.env.REACT_APP_TOKEN)
  // }

  //session
  // const token = sessionStorage.getItem(process.env.REACT_APP_TOKEN);
  // const setToken = (value) => {
  //   sessionStorage.setItem(process.env.REACT_APP_TOKEN, value);
  // }
  // const removeToken = () => {
  //   sessionStorage.removeItem(process.env.REACT_APP_TOKEN);
  // }