import React, { useEffect, useContext, createContext } from "react";
import { useImmer } from "use-immer";
import _ from "lodash";

const defaultState = {
  menuVisible: false,
  navItems: [],
  roleNavItems: [],
  user: null,
  roleCode: null,
  ///各頁權限
  orderCommentItems: false,
  itemViewItems: false,
  shipmentItems: false,
  commentItems: false,
  accountingItems: false,
  logItems: false,
  paymentItems: false,
  paymentNoticeItems: false,
  drawingItems: false,
  drawingAccountItems: false,
  reportItems: false
};

const LocalStateContext = createContext();

const useLocalState = () => {
  const [state, produce] = useContext(LocalStateContext);

  const toggleSidebar = () => {
    produce((draft) => void (draft.menuVisible = !draft.menuVisible));
  };

  const toggleSidebarDropdown = (item) => {
    produce((draft) => {
      const items = _.flatten(_.map(draft.navItems, "items"));

      item = _.find(items, item);
      item.open = !item.open;

      _.reject(items, item).forEach((item) => (item.open = false));
    });
  };

  const setUser = (result) => {
    if(result === null){
      produce((draft) => {
        draft.navItems = []
        draft.orderCommentItems = false
        draft.itemViewItems = false
        draft.shipmentItems = false
        draft.commentItems = false
        draft.accountingItems = false
        draft.logItems = false
        draft.paymentItems = false
        draft.paymentNoticeItems = false
        draft.drawingItems = false
        draft.drawingAccountItems = false
        draft.reportItems = false
        draft.user = null
      });
      return;
    }
    produce((draft) => { 
      draft.user = result?.user
      draft.roleCode = result?.user?.role_code
    });
    
    if(result?.user){
      produce((draft) => {
        draft.navItems = result.progs ? _.groupBy(result.progs, 'menutitle') : []
        draft.roleNavItems = result.progs ? result.progs : []
      });
   
      produce((draft) => {
        _.map(result.progs, 
        (o,key) => {o.name === "訂購單交期處理" && _.map(o, draft.orderCommentItems = true),
                    o.name === "出貨處理列印" && _.map(o, draft.shipmentItems = true),
                    o.name === "跟催回覆-通知" && _.map(o, draft.commentItems = true),
                    o.name === "整帳作業" && _.map(o, draft.accountingItems = true),
                    o.name === "LOG設定" && _.map(o, draft.logItems = true),
                    o.name === "支給單處理" && _.map(o, draft.paymentItems = true),
                    o.name === "支給單通知" && _.map(o, draft.paymentNoticeItems = true),
                    o.name === "圖面簽收" && _.map(o, draft.drawingItems = true),
                    o.name === "圖面統計" && _.map(o, draft.drawingAccountItems = true),
                    o.name === "檢驗報告設定" && _.map(o, draft.reportItems = true)
                    o.name === "圖面視圖" &&  _.map(o, draft.itemViewItems = true)
         });
       });
    }    
  };

  return {
    ...state,
    toggleSidebar,
    toggleSidebarDropdown,
    setUser,
  };
};

const LocalStateProvider = ({ children }) => {
  const [state, produce] = useImmer({ ...defaultState });
  return (
    <LocalStateContext.Provider value={[state, produce]}>
      {children}
    </LocalStateContext.Provider>
  );
};

export default useLocalState;
export { LocalStateProvider };
