import React from "react";
import { Container, Row, Col } from "shards-react";
import { css } from "@emotion/core";
import images from "../images/images";
const BlankLayout = ({ children, noNavbar, noFooter }) => (
  <>

        <Container fluid className="icon-sidebar-nav" css={style2}>
          <Row>
            <Col className="login-content col p-0 " tag="main">
              {children}
            </Col>
          </Row>
        </Container>
        <footer className="tme_copyright"  style={{ zIndex: 1 }} >
          <div className="gs18-Footer__Links gs18-Footer__Links--withme">
            <small className="gs18-Footer__Copyright">© TAIWAN MITSUBISHI ELEVATOR CO.,LTD.</small>
          </div>
        </footer> 
  </>
);

export default BlankLayout;

const style2 = css`
overflow: hidden;
`

const styles = css`
  font-family: PingFangTC-Regular;
  font-size: 12px;
  color: #b3b3b3;
  letter-spacing: 0.08px;
  text-align: center;
  width: 100%;
  background: #f1f1f1;
`;
