export default [
    {title: '系統公告', name:'系統公告'},
    {title: '個人資料維護', name: '個人資料維護'},
    {title: '訂購單交期處理', name: '訂購單交期處理'},
    {title: '訂購單交期處理', name: '訂購單交期狀態'},
    {title: '訂購單交期處理', name: '摘要表'},
    {title: '出貨處理', name: '出貨處理'},
    {title: '出貨處理', name: '出貨處理列印'},
    {title: '跟催回覆-通知', name: '跟催回覆-通知'},
    {title: '跟催回覆-通知', name: '所有跟催查詢'},
    {title: '跟催回覆-回覆', name: '跟催回覆-回覆'},
    {title: '整帳作業', name: '整帳作業'},
    {title: '整帳作業', name: '整帳作業狀態'},
    {title: '外注核價', name: '外注核價'},
    {title: '支給單', name: '支給單處理'},
    {title: '支給單', name: '支給單通知'},
    {title: '支給單', name: '臨注單處理'},
    {title: '圖面簽收統計', name: '圖面簽收'},
    {title: '圖面簽收統計', name: '圖面統計'},
    {title: '進料檢驗報告', name: '檢驗報告設定'},
    {title: '進料檢驗報告', name: '檢驗報告上傳'},
    {title: '公告設定', name: '公告設定'},
    {title: '帳號管理', name: '帳號管理'},
    {title: '群組設定', name: '群組設定'},
    {title: '條文設定', name: '條文設定'},
    {title: 'LOG設定', name: 'LOG設定'},
    {title: 'LOG設定', name: '登入紀錄'},
    {title: '收料單位設定', name: '收料單位設定'},
    {title: '品管擔當設定', name: '品管擔當設定'},
    {title: '系統設定', name: '系統設定'},
    {title: '程式設定', name: '程式設定'},
    {title: '目錄設定', name: '目錄設定'}
]