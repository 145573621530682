import React, { useState } from "react";
import { Link as _Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
  Col,
} from "shards-react";
import { css } from "@emotion/core";

import useAuth from "../../../../hooks/use-auth";
import useLocalState from "../../../../hooks/use-local-state";

const Link = (props) => <_Link {...props} />;

const UserActions = () => {
  const [visible, setVisible] = useState(false);
  const { signOut } = useAuth();
  const { user } = useLocalState();

  const toggleUserActions = () => setVisible(!visible);

  return (
    <Col className="text-nowrap px-3" css={styles}>
      <label css={textStyles}>
        <i className="fa fa-smile mr-1" />
        <span className="d-none d-md-inline-block">
          {`Hello, ${user?.name ? user?.name : ""}`}</span>
        <span onClick={() => signOut()} css={logoutStyles}>
          登出
        </span>
      </label>
    </Col>
    // <NavItem tag={Dropdown} caret toggle={toggleUserActions}>
    //   <DropdownToggle
    //     caret
    //     tag={NavLink}
    //     className="text-nowrap px-3"
    //     style={{ marginTop: "10px" }}
    //   >
    //     <span className="d-none d-md-inline-block">{`Hi ! ${
    //       user?.name ? user?.name : ""
    //     }`}</span>
    //   </DropdownToggle>
    //   <Collapse tag={DropdownMenu} right small open={visible}>

    //     <DropdownItem divider />
    //     <DropdownItem
    //       onClick={() => signOut()}
    //       tag={Link}
    //       to="login"
    //       style={{ color: "#F65657", fontWeight: "500" }}
    //     >
    //       登出
    //     </DropdownItem>
    //   </Collapse>
    // </NavItem>
  );
};

export default UserActions;

const styles = css`
  text-align: center;
  margin-right: 10px;
`;

const logoutStyles = css`
  padding-left: 20px;
  color: #f65657;
  font-weight: 500;
  cursor: pointer;
`;

const textStyles = css`
  margin-top: 20px;
`;
