import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import { NavLink as _RouteNavLink } from "react-router-dom";
import {
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownItem,
  Collapse,
} from "shards-react";
import _ from "lodash";
import useLocalState from "../../../hooks/use-local-state";
import useRouter from "../../../hooks/use-router";
import list from "../../../data/menu-relation";
import menuName from "../../../data/menu-name";

const RouteNavLink = (props) => <_RouteNavLink {...props} />;

const SidebarNavItem = ({ item }) => {
  const { toggleSidebarDropdown } = useLocalState();
  const [hover, setHover] = useState(false);
  const { history, location } = useRouter();
  const currentUrl = location.pathname.split('/')[1];
  const activeUrl = _.find(list, ['url', currentUrl])?.type
  var isActive = _.isEqual(activeUrl, item.path)
  // var isActive = String(location.pathname).includes(String(`/${item.path}`))
  return (
    <NavItem css={styles(item, isActive)} style={{ position: "relative"}}>
      <NavLink
        active={isActive}
        style={{ display: "flex" }}
        activeclassname="custom-active"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        tag={RouteNavLink}
        to={{
          pathname: `/${item.path}`,
        }}
        // onClick={() => {
        //   toggleSidebarDropdown(item);
        // }}
      >
        {item.icon && 
          <div>
            <i className={item.icon} />
          </div>
        }    
        {item.name && <span>{_.find(menuName, ['name', item.name])?.title}</span>}
      </NavLink>
    </NavItem>
  );
};

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object,
};

const styles = (item, hover) => css`
   {
    .iconImage {
      height: 26px;
      width: 26px;
      background-size: contain;
    }

    .custom-active span {
      color: var(--pixis-color);
    }
    .custom-active {
      box-shadow: inset 0.1875rem 0 0 var(--pixis-color);
      background-color: #fbfbfb;
      color: var(--pixis-color);
    }
  }
`;

export default SidebarNavItem;
