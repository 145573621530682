import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import { LocalStateProvider } from "./hooks/use-local-state";
import { AuthProvider } from "./hooks/use-auth";
import { ApiProvider } from "./hooks/use-api";
import routes from "./routes";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/tme-style/style.css"
import "./styles/tme-style/layouts.css"
import "./styles/tme-style/scm.css"
import "./assets/main.scss";

export default () => (
  <LocalStateProvider>
    <CookiesProvider>
      <AuthProvider>
        <ApiProvider>
            <Router basename={process.env.REACT_APP_BASE_NAME}>
              <Switch>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={(props) => (
                      <route.layout {...props}>
                        <route.component {...props} />
                      </route.layout>
                    )}
                  />
                ))}
              </Switch>
          </Router>
        </ApiProvider>
      </AuthProvider>
    </CookiesProvider>
  </LocalStateProvider>
);
