import _ from "lodash";
import useApi, { API_METHOD } from "../hooks/use-api";
import { USER, PROFILE } from "./API";

const useUser = () => {
  const { FetchApi } = useApi();

  const getProfile = async () => {
    const { status, message, result } = await FetchApi(
      API_METHOD.GET,
      PROFILE,
      null
    );
    return { status, message, result };
  };

  const updateProfile = async (data) => {
    const { status, message, result } = await FetchApi(
      API_METHOD.POST,
      PROFILE,
      data
    );
    return { status, message };
  };

  const getUserList = async () => {
    const { status, message, result } = await FetchApi(
      API_METHOD.GET,
      USER,
      null
    );
    return { status, message, result };
  };

  const getUser = async (id) => {
    const { status, message, result } = await FetchApi(
      API_METHOD.GET,
      `${USER}/${id}`,
      null
    );
    return { status, message, result };
  };

  const createUser = async (data) => {
    const { status, message, result } = await FetchApi(
      API_METHOD.POST,
      USER,
      data
    );
    return { status, message };
  };

  const updateUser = async (data) => {
    const { status, message, result } = await FetchApi(
      API_METHOD.POST,
      `${USER}/${data.id}`,
      data
    );
    return { status, message };
  };

  const deleteUser = async (id) => {
    const { status, message, result } = await FetchApi(
      API_METHOD.DELETE,
      `${USER}/${id}`,
      null
    );
    return { status, message, result };
  };

  const searchUser = async (data) => {
    const { status, message, result } = await FetchApi(
      API_METHOD.POST,
      `${USER}/search`,
      data
    );
    return { status, message, result };
  };

  return { getProfile, updateProfile, getUserList, getUser, updateUser, createUser, deleteUser, searchUser };
};

export default useUser;
