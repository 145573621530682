export default {
  logo_new: require("./logo/backend_logo.png"),

  menu_01: require("./menu/menu_01.png"),
  menu_02: require("./menu/menu_02.png"),
  menu_03: require("./menu/menu_03.png"),
  menu_04: require("./menu/menu_04.png"),
  menu_05: require("./menu/menu_05.png"),
  menu_dropdown_01: require("./menu/menu_dropdown_01.png"),
  menu_dropdown_02: require("./menu/menu_dropdown_02.png"),

  background: require("./background/background.png"),
  background2: require("./background/background@2x.png"),

  closeBtn: require("./close/close.png"),
  employee: require("./employee.pdf"),
  tme_logo: require("./logo/tme_logo.png"), 
};
