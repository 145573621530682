import "../assets/style.scss";
import Images from '../images/images';

const SideMenu = () => {
  return (
    <div className="side-menu">
      <ul>
        <li className="cell">
          <a href="/Pubnote">系統公告</a>
        </li>
        <li className="cell">
          <a href="/Profile">個人資料維護</a>
        </li>
        <li className="cell">
          <a href="/OrderComment">訂購單交期處理</a>
        </li>
        <li className="cell">
          <a href="/Shipment">出貨處理</a>
        </li>
        <li className="cell">
          <a href="/Comment_status">跟催回覆-通知</a>
        </li>
        <li className="cell">
          <a href="/Comment_reply">跟催回覆-回覆</a>
        </li>
        <li className="cell">
          <a href="/Accounting_status">整帳作業</a>
        </li>
        <li className="cell">
          <a href="/Cost">外注核價</a>
        </li>

        {/* 管理者功能 */}
        <li className="cell">
          <a href="/Pubnote">公告設定</a>
        </li>
        <li className="cell">
          <a href="/User">帳號設定</a>
        </li>
        <li className="cell">
          <a href="/Role">群組設定</a>
        </li>
        <li className="cell">
          <a href="/Terms">條文設定</a>
        </li>
        <li className="cell">
          <a href="/UserHistories">LOG設定</a>
        </li>
        <li className="cell">
          <a href="/Receive">收料單位設定</a>
        </li>
        {/* 系統管理 */}
        <li className="cell">
          <a href="/Menu">目錄設定</a>
        </li>
        <li className="cell">
          <a href="/Prog">程式設定</a>
        </li>
        <li className="cell">
          <a href="/System">系統設定</a>
        </li>   
      </ul>
    </div>
  );
}
export default SideMenu;
