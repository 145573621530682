import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand } from 'shards-react';

import Images from '../../../images/images'
import useLocalState from '../../../hooks/use-local-state';

const SidebarMainNavbar = ({ hideLogoText }) => {
  const { toggleSidebar } = useLocalState();

  return (
    <div className="main-navbar">
      <Navbar className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0" type="light">
        <NavbarBrand className="w-100 mr-0" href="/Pubnote_Read" style={{ lineHeight: '25px' }}>
          <div className="d-table m-auto">
            <label
              id="main-logo"
              // className="d-inline-block align-top mr-1"
              style={{ fontSize: 28, marginTop: 5}}
              // src={Images.logo_new}
              // alt=""
              >
              台灣三菱電梯
            </label>
          </div>
        </NavbarBrand>
        {/* eslint-disable-next-line */}
        {/* <a className="toggle-sidebar d-sm-inline d-md-none d-lg-none" onClick={toggleSidebar}>
          <i className="material-icons">&#xE5C4;</i>
        </a> */}
      </Navbar>
    </div>
  );
};

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false
};

export default SidebarMainNavbar;
