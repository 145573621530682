//系統公告
export const PUBNOTE_READ = "pubnote_read"

//個人資料維護
export const PROFILE = "profile"

//訂購單交期處理
export const ORDER_COMMENT = "order_comment"
export const ORDER_STATUS = "order_status"
export const TREE = "tree"
export const SUMMARY = "summary"
export const ITEM_VIEW = "item_view"

//出貨處理
export const SHIPMENT = "shipment"
export const SHIPMENT_PRINT = "shipment_print"

//跟催回覆-通知
export const COMMENT = "comment"
export const COMMENT_STATUS = "comment_status"

//跟催回覆-回覆
export const COMMENT_REPLY = "comment_reply"

//整帳作業
export const ACCOUNTING = "accounting"
export const ACCOUNTING_STATUS = "accounting_status"

//外注核銷
export const COST = "cost"

//支給單
export const PAYMENT = "payment"
export const PAYMENT_NOTICE = "payment_notice"
export const TEMPORARY = "temporary"

//圖面簽收
export const DRAWING = "drawing"

//進料檢驗報告
export const REPORT_SETTING = "report_setting"
export const REPORT_UPLOAD = "report_upload"

//管理者功能
export const LOGIN = "login"
export const USER_HISTORIES = "userhistories"
export const RECEIVE = "receive"
export const TERM = "terms"
export const ROLE_MENU = "roles/menu"
export const ROLE = "roles"
export const USER = "users"
export const PUBNOTE = "pubnote"
export const LOG_LOGIN = "log_login"
export const QUALITY_CONTROL = "qc"
export const PASSWORD_CHECK = "password_check"
export const PASSWORD_RESET = "password_reset"

//系統管理
export const SYSTEM = "system"
export const PROG = "progs"
export const MENU = "menus"