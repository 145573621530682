import React, { useContext, createContext, useEffect } from "react";
import { useImmer } from "use-immer";
import Dialog from "../components/common/Dialog";
import useAuth from "./use-auth";
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_SERVER_ENDPOINT}`;
const APIKit = axios.create({
  baseURL: BASE_URL,
  timeout: 180000,
});

APIKit.interceptors.request.use(function (config) {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN);
  config.headers.Authorization = `Bearer ${!!token ? token : ""}`;
  return config;
});

export const API_METHOD = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

const ApiContext = createContext();

const authMessage = "登入憑證已失效，請重新登入";
const defaultFailedMessage = "系統異常，請稍後再試";

const useApi = () => useContext(ApiContext);

const ApiProvider = ({ children }) => {
  const api = useApiProvider();
  const { errorMessage, clearErrorMessage, signOut } = api;

  const Action = () => {
    if (errorMessage == authMessage) signOut();
    clearErrorMessage();
  };

  return (
    <ApiContext.Provider value={api}>
      <Dialog
        title="警告"
        message={errorMessage}
        isShowDialog={errorMessage != null}
        overlayAction={() => Action()}
        confirmButtonProp={{
          action: () => Action(),
        }}
        cancelButtonProp={{
          isShow: false,
        }}
      />
      {children}
    </ApiContext.Provider>
  );
};

const useApiProvider = () => {
  const { token, signOut } = useAuth();
  const [state, produce] = useImmer({
    errorMessage: null,
  });

  const { errorMessage } = state;

  const clearErrorMessage = () => {
    produce((draft) => void (draft.errorMessage = null));
  };

  const setErrorMessage = (value) => {
    produce((draft) => void (draft.errorMessage = value));
  };

  const setExpiredMessage = () => setErrorMessage(authMessage);

  const FetchApi = async (method, path, variables, noError) => {
    let result = { status: 1, message: "error", result: null };
    try {
      const { data } = await APIKit[method](path, variables);
      result = data;
      if (result.status != 0 && !noError) setErrorMessage(data.message);
    } catch (error) {
      let message = defaultFailedMessage;
      let statusCode = error.response?.status;

      if (statusCode == 401) message = authMessage;

      result = { status: statusCode, message: message, result: null };
      if (!noError) setErrorMessage(message);
    } finally {
      return result;
    }
  };

  return { errorMessage, clearErrorMessage, signOut, FetchApi, setExpiredMessage };
};

export default useApi;
export { ApiProvider };
