// Layout Types
import { DefaultLayout, BlankLayout } from "./layouts";
import { Redirect } from "react-router-dom";
import loadable from "@loadable/component"

export default [
  {
    path: "/",
    exact: true,
    layout: BlankLayout,
    component: loadable(() => import ("./views/Landing"))
  },
  {
    path: "/Login",
    layout: BlankLayout,
    component: loadable(() => import ("./views/login/Login"))
  },
  {
    path: "/pubnote_read/show",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/pubnote/PubnoteReadShow"))
  },
  {
    path: "/pubnote_read",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/pubnote/PubnoteReadList"))
  },
  {
    path: "/profile",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/profile/ProfileEdit"))
  },
  {
    path: "/order_comment/treeview",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/order/TreeView"))
  },
  {
    path: "/order_comment/show",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/order/OrderCommentShow"))
  },
  {
    path: "/order_comment",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/order/OrderCommentList"))
  },
  {
    path: "/order_status/show",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/order/OrderStatusShow"))
  },
  {
    path: "/order_status/treeview",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/order/TreeView"))
  },
  {
    path: "/order_status",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/order/OrderStatusList"))
  },
  {
    path: "/summary/detail",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/order/SummaryDetail"))
  },
  {
    path: "/summary",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/order/SummaryList"))
  },
  {
    path: "/item_view",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/order/ItemViewList"))
  },
  {
    path: "/shipment/show",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/shipment/ShipmentShow"))
  },
  {
    path: "/shipment",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/shipment/ShipmentList"))
  },
  {
    path: "/shipment_print",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/shipment/ShipmentPrint"))
  },
  {
    path: "/comment/show",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/comment/CommentShow"))
  },
  {
    path: "/comment",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/comment/CommentList"))
  },
  {
    path: "/comment_status/show",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/comment/CommentStatusShow"))
  },
  {
    path: "/comment_status",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/comment/CommentStatusList"))
  },
  {
    path: "/comment_reply/show",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/comment/CommentReplyShow"))
  },
  {
    path: "/comment_reply",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/comment/CommentReplyList"))
  },
  {
    path: "/accounting/finish",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/accounting/AccountingFinish"))
  },
  {
    path: "/accounting",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/accounting/AccountingList"))
  },
  {
    path: "/accounting_status",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/accounting/AccountingStatusList"))
  },
  {
    path: "/cost/edit",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/cost/CostEdit"))
  },
  {
    path: "/cost",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/cost/CostList"))
  },
  {
    path: "/pubnote/edit",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/pubnote/PubnoteEdit"))
  },
  {
    path: "/pubnote/show",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/pubnote/PubnoteShow"))
  },
  {
    path: "/pubnote",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/pubnote/PubnoteList"))
  },
  {
    path: "/user/edit",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/user/UserEdit"))
  },
  {
    path: "/user/show",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/user/UserShow"))
  },
  {
    path: "/user",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/user/UserList"))
  },
  {
    path: "/roles/edit",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/role/RoleEdit"))
  },
  {
    path: "/roles/show",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/role/RoleShow"))
  },
  {
    path: "/roles",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/role/RoleList"))
  },
  {
    path: "/userhistories",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/log/UserHistories"))
  },
  {
    path: "/log_login",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/log/LogLogin"))
  },
  {
    path: "/terms",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/term/TermEdit"))
  },
  {
    path: "/receive/edit",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/receive/ReceiveEdit"))
  },
  {
    path: "/receive",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/receive/ReceiveList"))
  },
  {
    path: "/system/edit",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/system/SystemEdit"))
  },
  {
    path: "/system/show",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/system/SystemShow"))
  },
  {
    path: "/system",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/system/SystemList"))
  },
  {
    path: "/progs/edit",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/prog/ProgEdit"))
  },
  {
    path: "/progs/show",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/prog/ProgShow"))
  },
  {
    path: "/progs",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/prog/ProgList"))
  },
  {
    path: "/menus/edit",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/menu/MenuEdit"))
  },
  {
    path: "/menus/show",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/menu/MenuShow"))
  },
  {
    path: "/menus",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/menu/MenuList"))
  },
  {
    path: "/errors",
    layout: BlankLayout,
    component: loadable(() => import ("./views/Errors"))
  },
  //new
  {
    path: "/payment/show",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/payment/PaymentShow"))
  },
  {
    path: "/payment",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/payment/PaymentList"))
  },
  {
    path: "/payment_notice",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/payment/PaymentNoticeList"))
  },
  {
    path: "/temporary",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/payment/TemporaryList"))
  },
  {
    path: "/drawing",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/drawing/DrawingList"))
  },
  {
    path: "/drawing_account",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/drawing/DrawingAccountList"))
  },
  {
    path: "/term",
    layout: BlankLayout,
    component: loadable(() => import ("./views/order/Term"))
  },
  {
    path: "/report",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/report/ReportList"))
  },
  {
    path: "/report_upload",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/report/ReportUploadList"))
  },
  {
    path: "/quality_control/edit",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/quality/QualityControlEdit"))
  },
  {
    path: "/quality_control",
    layout: DefaultLayout,
    component: loadable(() => import ("./views/quality/QualityControlList"))
  },
  {
    path: "/",
    exact: false,
    layout: DefaultLayout,
    component: () => <Redirect to="/" />,
  },
];
