import { useEffect } from "react";
import _ from "lodash";
import moment from "moment";

import useRouter from "./use-router";
import useAuth from "./use-auth";
import useUser from "../api/user-service";
import useLocalState from "./use-local-state";
import routes from "../routes";
import useApi from "../hooks/use-api";

const useRequireAuth = (redirectUrl = "/Login") => {
  const auth = useAuth();
  const { token, expired, setExpired, removeExpired } = auth;
  const router = useRouter();
  const { getProfile } = useUser();
  const { setUser, roleNavItems } = useLocalState();
  const { setExpiredMessage } = useApi();

  const getAdmin = async () => {
    const { status, message, result } = await getProfile();
    if (status == "0") setUser(result);
  };

  useEffect(() => {
    if (_.includes(router.pathname, "/Term")) return;
    if (!auth.token) {
      router.push(redirectUrl);
    }
    if( 
      !!roleNavItems && 
      roleNavItems.length > 0 &&
      !_.find(roleNavItems, o => {
        return _.isEqual(String(router.pathname).split('/')[1], o.path)
      })
    )
    router.push( _.first(roleNavItems)?.path);
  }, [auth, router]);

  useEffect(() => {
    if (!token) return;
    if (moment().isAfter(expired) || !expired) {
      setExpiredMessage();
      removeExpired();
    }else setExpired(moment().add(process.env.REACT_APP_TOKEN_EXPIRED, "minutes"));
    getAdmin();
  }, [token]);

  return auth;
};

export default useRequireAuth;
