import React from 'react';
import _ from "lodash";
import { Nav } from 'shards-react';
import { Scrollbars } from 'react-custom-scrollbars';
import SidebarNavItem from './SidebarNavItem';
import SidebarNavTitle from './SidebarNavTitle'
import SidebarFooterItems from './SidebarFooterItems';
import useLocalState from '../../../hooks/use-local-state';

const SidebarNavItems = props => {
  const { navItems: items } = useLocalState();

  return (
    <Scrollbars className="scroll-nav-wrapper mainsider-submenu"  autoHide>
      <div className="nav-wrapper">
        {_.map(items, (nav, idx) => (
          <div key={idx}>
            {idx === "-" ? (
              <Nav className="nav--no-borders flex-column">
                {_.map(_.orderBy(nav, ["primary_prog"], ["desc"]), (o, idx) => ( 
                  ((o.primary_prog === null || _.find(nav, ["id", parseInt(o.primary_prog)]) === undefined) && o.visible !== "0") 
                  && (<SidebarNavItem key={idx} item={o} />)
                ))}
              </Nav>
            ) :
            (
              <Nav className="nav--no-borders flex-column">
                  <SidebarNavTitle key={idx} item={nav} />
              </Nav>
            )}
          </div>
        ))}
      </div>
      <SidebarFooterItems />
    </Scrollbars>
  );
};

export default SidebarNavItems;