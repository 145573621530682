import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import useRequireAuth from "../hooks/use-require-auth";
import images from "../images/images";
import MainNavbar from "../components/layout/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar";
import { css } from "@emotion/core";

const DefaultLayout = ({ children, noNavbar }) => {
  const auth = useRequireAuth();

  return (
    <>
        <header className="gs18-Header gs18-Header--MinInteraction gs18-Header--NoSearch gs18-Header--withme no-print" role="banner" data-js-gs18-header>
          <div className="gs18-Header__Inner">
            <h1 className="gs18-Header__Logo">
                <img src={images.tme_logo}  />
            </h1>
          </div>
        </header>
        <Container fluid>
          <Row>
            <MainSidebar />
            <Col
              className="main-content p-0"
              // lg={{ size: 10, offset: 2 }}
              // md={{ size: 9, offset: 3 }}
              sm="12"
              tag="main"
            >
              {!noNavbar && <MainNavbar />}
              {children}
            </Col>
          </Row>
        </Container>
        <footer className="tme-in-copyright no-print"   >
            <div className="gs18-Footer__Links gs18-Footer__Links--withme">
              <small className="gs18-Footer__Copyright">© TAIWAN MITSUBISHI ELEVATOR CO.,LTD.</small>
            </div>
        </footer>
    </>
  );
};

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default DefaultLayout;

const styles = css`
  position: relative;
  font-family: PingFangTC-Regular;
  font-size: 12px;
  color: #b3b3b3;
  letter-spacing: 0.08px;
  text-align: center;
  width: 100%;
`;
