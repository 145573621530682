export default [
    {url: 'order_comment', type:'order_comment'},
    {url: 'order_status', type: 'order_comment'},
    {url: 'summary', type: 'order_comment'},
    {url: 'shipment', type: 'shipment'},
    {url: 'shipment_print', type: 'shipment'},
    {url: 'comment', type: 'comment'},
    {url: 'comment_status', type: 'comment'},
    {url: 'accounting', type: 'accounting'},
    {url: 'accounting_status', type: 'accounting'},
    {url: 'payment', type:'payment'},
    {url: 'payment_notice', type:'payment'},
    {url: 'temporary', type:'payment'},
    {url: 'drawing', type: 'drawing'},
    {url: 'drawing_account', type: 'drawing'},
    {url: 'report_upload', type: 'report_upload'},
    {url: 'report', type: 'report_upload'},
]