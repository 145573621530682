import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col } from 'shards-react';

import SidebarMainNavbar from './SidebarMainNavbar';
import SidebarSearch from './SidebarSearch';
import SideMenu from "../../../views/SideMenu";
import SidebarNavItems from './SidebarNavItems';
import SidebarFooterItems from './SidebarFooterItems';
import useLocalState from '../../../hooks/use-local-state';

const MainSidebar = props => {
  const { menuVisible } = useLocalState();
  const classes = classNames(
    'main-sidebar',
    'px-0',
    'no-print',
    menuVisible && 'open');

  return (
    <Col className={classes} >
         {/* <Col tag="aside" className={classes} lg={{ size: 2 }} md={{ size: 3 }}></Col> */}
      <SidebarMainNavbar hideLogoText={props.hideLogoText} />
      {/* <SidebarSearch /> */}
      <SidebarNavItems />
      {/* <SideMenu /> */}
      {/* <SidebarFooterItems /> */}
    </Col>
  );
};

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};

export default MainSidebar;
