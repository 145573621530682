import React from 'react';
import { Row, Navbar, NavbarBrand } from 'shards-react';

import Images from '../../../images/images'
import useLocalState from '../../../hooks/use-local-state';
const SidebarFooterItems = ({ hideLogoText }) => {

  return (
    <div className="main-navbar">
      <Navbar className="align-items-stretch bg-white flex-md-nowrap border-top" type="light" style={{paddingTop: 3, justifyContent: 'center'}}>
        <Row>
          <a
              className="w-100 text-center"
              style={{ fontSize: 12 }}
              href="/Term"
              target="_blank"
              >
                  <i className="material-icons">&#xE258;</i> 合約內容
          </a>
          <a
              className="w-100 text-center"
              style={{ fontSize: 12 }}
              href={Images.employee}
              download
              >
                  <i className="fa fa-download"/> 操作說明下載
          </a>

          {/* <label
              className="w-100 text-center"
              style={{ fontSize: 12}}
          >
              © 2018 台灣三菱電梯
          </label> */}
        </Row>
      </Navbar>
    </div>
  );
};

export default SidebarFooterItems;
